<template>
    <app-page title="Nous Contacter">
        <section>
            <center-container col-class="col-xs-no-gutter">
                <div v-for="(etab, index) in etabs" :key="index">
                    <b-card class="card--header-sm mb-0 mb-sm-5" v-if="etab.hasActiveContract">
                        <template #header>
                            <p class="h4 text-center">Notre adresse</p>
                        </template>
                        <div class="d-flex align-items-center">
                            <div class="w-25 flex-shrink-0">
                                <b-img :src="$appVersion('logoImage')"
                                    fluid></b-img>
                            </div>
                            <div class="flex-shrink-0 flex-grow-1 d-flex justify-content-center px-2">
                                <div>
                                    <!--                                <p class="font-weight-bold">{{ etab.branch_name }}</p>-->
                                    <p style="word-wrap: break-word; max-width: 70vw" v-html="getEtabAdresse(etab)"></p>
                                    <b-link class="d-flex align-items-center font-weight-bold mt-2"
                                            :href="`tel:${etab.phone}`">
                                        <b-icon-phone class="h3 mr-1"/>
                                        <span class="line-height-fit">{{ etab.phone }}</span>
                                    </b-link>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-around align-items-center mt-4">
                            <b-button size="sm"
                                    type=""
                                    v-b-toggle.contact-form
                                    @click="selectedEtab = etab"
                                    variant="primary">Nous contacter
                            </b-button>
                            <b-button size="sm"
                                    type=""
                                    @click="getMap(etab)"
                                    variant="primary">Voir sur la carte
                            </b-button>
                        </div>
                    </b-card>
                </div>
                    <b-sidebar id="contact-form" right title="Formulaire de contact" bg-variant="white" width="90vw">
                        <div class="mb-0 mb-sm-5">
                            <b-form @submit.prevent="messageAction" class="p-2">
                                <b-form-select class="mb-2"
                                            :class="{ 'text-gray': !contactForm.type }"
                                            v-model="contactForm.type"
                                            required>
                                    <b-form-select-option :value="null"
                                                        disabled>Votre demande concerne
                                    </b-form-select-option>
                                    <b-form-select-option v-for="option in options"
                                                        :value="option.value"
                                                        :key="`option-${option.value}`">{{ option.text }}
                                    </b-form-select-option>
                                </b-form-select>
                                <b-form-input class="mb-2"
                                            v-model="contactForm.subject"
                                            type="text"
                                            placeholder="Sujet"
                                            required></b-form-input>
                                <b-form-textarea class="mb-3"
                                                v-model="contactForm.description"
                                                placeholder="Description"
                                                rows="4"
                                                max-rows="15"
                                                no-resize
                                                required></b-form-textarea>
                                <div class="d-flex justify-content-center">
                                    <b-button size="lg"
                                            type="submit"
                                            v-b-toggle.contact-form
                                            variant="primary">Envoyer
                                    </b-button>
                                </div>
                            </b-form>
                        </div>
                    </b-sidebar>
            </center-container>
        </section>
    </app-page>
</template>

<script>
import {BIconPhone} from 'bootstrap-vue'
import AppPage from '@/components/layout/AppPage'
import CenterContainer from '@/components/layout/CenterContainer'
import SimpleModal from '@/components/layout/elements/SimpleModal'
import contactService from '../../../services/contactService'
import contratsService from '../../../services/contratsService'
import AlertHandler from "@/alertHandler";
import compteService from "../../../services/compteService";

export default {
    components: {SimpleModal, CenterContainer, AppPage, BIconPhone},
    async mounted() {
        try {
            const res = await contratsService.getEtabs();
            if (res.etat == 'KO') throw 'KO'
            this.etabs = res.data.data;
            const res2 = await compteService.getInformations();
            if (res2.etat == 'KO') throw 'KO'
            this.customer = res2.data.data;
        } catch (error) {
            AlertHandler.show({code: 4009, error});
        }
    },
    data() {
        return {
            contactForm: {
                type: null,
                subject: '',
                description: '',
                customer: null
            },
            options: [
                {
                    value: 'Mission',
                    text: 'Une mission'
                },
                {
                    value: 'Contrat',
                    text: 'Votre contrat'
                },
                {
                    value: 'Autre',
                    text: 'Une autre raison'
                }
            ],
            etabs: [],
            selectedEtab: null
        }
    },
    computed: {
        gmapData() {
            return {
                lat: this.selectedEtab?.adresse.latitude,
                lng: this.selectedEtab?.adresse.longitude,
                title: this.selectedEtab?.branch_name
            }
        }
    },
    methods: {
        getEtabAdresse(etab) {
            const adr = etab.adresse ?? {};
            return [adr.adress1, adr.adress2, adr.adress3, '<br>', adr.postal_code, adr.city, adr.country].filter(i => !!i).join(' ');
        },
        messageAction() {
            const data = {
                objet: this.contactForm.type + " - " + this.contactForm.subject,
                message: this.contactForm.description,
                branch: this.selectedEtab,
                customer: this.customer
            }

            contactService.sendContactMessage(data).then((response) => {
                if (response.data.etat == "OK") {
                    AlertHandler.show({code: 'messageSent'});
                    this.contactForm.type = null
                    this.contactForm.subject = ''
                    this.contactForm.description = ''
                } else {
                    AlertHandler.show({code: 4010});
                }
            }).catch((error) => {
                AlertHandler.show({code: 4011, error});
            })

        },
        getMap(etab) {
            const lat = etab.adresse?.latitude;
            const lng = etab.adresse?.longitude;
            if /* if we're on iOS, open in Apple Maps */
            ((navigator.platform.indexOf("iPhone") != -1) ||
                (navigator.platform.indexOf("iPod") != -1) ||
                (navigator.platform.indexOf("iPad") != -1))
                window.open("maps://maps.google.com/maps?daddr=" + lat + "," + lng + "&amp;ll=")
            else /* else use Google */
                window.open("https://maps.google.com/maps?daddr=" + lat + "," + lng + "&amp;ll=")
        }
    }
}
</script>