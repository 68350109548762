import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getInformations () {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/client/informations'
        
        return window.axios.get(url, config)
    },
    changeInformation (data) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/modifier-contact'

        return window.axios.post(url, data, config)
    }
}